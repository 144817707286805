
@import '../../../stylesheets/shared/content/fonts.postcss';
@import '../../../stylesheets/utils/mixins.postcss';
@import '../../../stylesheets/variables.postcss';

.GlobalFooter {
  position: relative;
  border-top: 1px solid $mediumGrey;
  width: 100%;
  overflow: hidden;

  text-align: center;

  @mixin above $medium {
    text-align: left;
  }
}

:global(.public-layout) .GlobalFooter {
  background: $lightGrey;
}

:global(.admin-layout) .GlobalFooter {
  background: none;
}

.GlobalFooter_section {
  margin-bottom: 2rem;

  @mixin above $medium {
    margin-bottom: 0;
  }
}

.GlobalFooter_end {
  .Container {
    overflow: hidden;
  }
}

.GlobalFooter_nav {
  margin-bottom: 1rem;
  text-align: center;

  @mixin above $medium {
    text-align: left;
    margin-bottom: 0rem;
  }
}

.GlobalFooter_finePrint {
  opacity: 1;
  text-align: center;
  font-size: 0.875rem;

  span {
    white-space: nowrap;
  }

  @mixin above $medium {
    text-align: left;
    display: inline-block;
    position: relative;
    top: 0.2rem;
  }
}

.GlobalFooter_social {
  display: flex;
  justify-content: center;

  a {
    &:hover {
      opacity: 50%;
    }

    & + a {
      margin-left: .5rem;
    }
  }

  img {
    height: 30px;
  }

  @mixin above $medium {
    justify-content: start;
  }
}

.GlobalFooter_navLink,
.GlobalFooter_heading {
  @mixin gordita-bold;
  color: $black !important;
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
}

.GlobalFooter_navLink {
  display: block;
  margin-bottom: 0.25rem;

  &:last-child {
    margin-right: 0;
  }

  @mixin above $medium {
    margin-bottom: 0;
    margin-right: 2rem;
    display: inline-block;
  }
}

.GlobalFooter_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @mixin above $medium {
    flex-direction: row;
  }
}

.InlineForm {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 1rem;
  width: 100%;
  font-size: 0.875rem;

  @mixin above $medium {
    justify-content: left;
  }

  :global(.Button) {
    height: unset;
    display: block;
    flex: 0 1 auto;
    background: $blue;
    border: 1px solid $blue;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    color: white;
    padding: 0.5rem 1rem;
  }

  input {
    @mixin gordita-regular;
    background: white;
    border: 1px solid $mediumGrey;
    border-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right-width: 0;
    box-shadow: none;
    max-width: calc(100% - 77px);
    padding: 0.5rem 1rem;
    width: 300px;
  }
}